<div class="pm-feedback-form">
  <div class="grid">
    <div class="col-12 lg:col-7 flex flex-column justify-content-center">
      <div class="pm-feedback-form__title pb-2">
        {{ 'feedback.unsure-how-to-apply-correctly' | transloco }}
      </div>
      <p>
        {{ 'feedback.feedback-form-description' | transloco }}
      </p>
      <div>
        <form [formGroup]="form">
          <div class="grid mb-3">
            <div class="col-12 md:col-4">
              <span class="p-float-label">
                <input
                  pInputText
                  id="name"
                  formControlName="name"
                  class="w-full"
                />
                <label htmlFor="name">{{ 'feedback.name' | transloco }}</label>
              </span>
            </div>
            <div class="col-12 md:col-4">
              <span class="p-float-label">
                <input
                  pInputText
                  id="email"
                  formControlName="email"
                  class="w-full"
                />
                <label htmlFor="email">{{
                  'feedback.email' | transloco
                }}</label>
              </span>
            </div>
            <div class="col-12 md:col-4">
              <span class="p-float-label">
                <input
                  pInputText
                  id="phone"
                  formControlName="phone"
                  class="w-full"
                />
                <label htmlFor="phone">{{
                  'feedback.phone' | transloco
                }}</label>
              </span>
            </div>
          </div>
          <div class="grid mb-3">
            <div class="col-12">
              <span class="p-float-label">
                <textarea
                  rows="5"
                  pInputTextarea
                  formControlName="message"
                  class="w-full"
                  resize="vertical"
                ></textarea>
                <label htmlFor="message">{{
                  'feedback.message' | transloco
                }}</label>
              </span>
            </div>
          </div>
          <div class="grid mb-3 flex gap-4 px-2">
            <p-button
              type="button"
              [label]="'feedback.contact-us' | transloco"
              [outlined]="true"
              [size]="'large'"
              (onClick)="onSubmit()"
            ></p-button>
            <p-button
              type="button"
              [label]="'feedback.open-chat' | transloco"
              [outlined]="true"
              [size]="'large'"
              severity="help"
              (onClick)="onOpenChat()"
            ></p-button>
          </div>
          <div class="text-sm">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a>
            apply.
          </div>
        </form>
      </div>
    </div>
    <div class="md:col-5 text-center hidden lg:block">
      <img
        #feedback
        src="/assets/feedback/feedback-{{ country }}.png"
        alt="Feedback form"
        srcset=""
        (error)="feedback.style.display = 'none'"
        (error)="stub.style.display = 'inline'"
      />
      <img
        #stub
        src="/assets/feedback/feedback-eu.png"
        alt="Feedback form"
        srcset=""
        style="display: none"
      />
    </div>
  </div>
</div>
