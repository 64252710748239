import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxStripeModule } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { filter, pairwise } from 'rxjs';

import { isPlatformServer, ViewportScroller } from '@angular/common';
import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_ID,
  ErrorHandler,
  Inject,
  Injector,
  NgModule,
  PLATFORM_ID,
  inject,
  provideAppInitializer,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
  Scroll,
} from '@angular/router';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { PersistStateModule } from '@ngrx-addons/persist-state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  LOCALIZE_ROUTER_CONFIG,
  localizeRouterConfig,
} from '@penleychan/ngx-transloco-router';
import { authInterceptorProviders } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig, getConfigValueFactory } from '@pm/config';
import { GeoLocationFacade, PmCoreDataModule } from '@pm/core/data';
import { PmCoreSharkModule } from '@pm/core/shark';
import { APP_TYPE, AppType } from '@pm/core/utils';
import { PmUiPmFooterModule } from '@pm/ui/footer';
import { PmUiPmHeaderModule } from '@pm/ui/header';
import * as Sentry from '@sentry/angular';

import { TranslocoService } from '@ngneat/transloco';
import { AppComponent } from './app.component';
import { appRoutes, uaAppRoutes } from './app.routes';
import { LayoutComponent } from './components/layout/layout.component';
import { cookieConfig } from './cookie-consent-config';
import { TranslocoRootModule } from './transloco-root.module';
import { PmCorePageNotFoundComponent } from '@pm/core/page';
import { LocaleProvider } from '@pm/core/locale';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      // scrollPositionRestoration: 'enabled',
    }),
    PmUiPmHeaderModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    PersistStateModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name: 'Profitmark EU',
    }),
    HttpClientModule,
    RecaptchaV3Module,
    PmUiPmFooterModule,
    GoogleTagManagerModule,
    PmCoreSharkModule,
    PmCoreDataModule,
    TranslocoRootModule,
    ToastModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxStripeModule.forRoot(),
    NgProgressModule,
    NgProgressRouterModule
  ],
  providers: [
    { provide: APP_ID, useValue: 'profitmark-web' },
    { provide: APP_TYPE, useValue: AppType.landing },
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
      withInterceptors([withHttpCacheInterceptor()]),
    ),
    provideClientHydration(),
    provideHttpCache(),
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: getConfigValueFactory('recaptchaKey'),
      deps: [Injector],
    },
    {
      provide: 'googleTagManagerId',
      useFactory: getConfigValueFactory('gtm'),
      deps: [Injector],
    },
    CookieService,
    ...authInterceptorProviders,
    provideAppInitializer(() => {
      const initializerFn = (
        (geo: GeoLocationFacade, injector: Injector) => () => {
          const platform = injector.get(PLATFORM_ID);
          if (isPlatformServer(platform)) {
            return Promise.resolve();
          }
          return geo.init();
        }
      )(inject(GeoLocationFacade), inject(Injector));
      return initializerFn();
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    MessageService,
    {
      provide: LOCALIZE_ROUTER_CONFIG,
      useValue: localizeRouterConfig(),
    },
    LocaleProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    @Inject(APP_CONFIG) private _config: AppConfig,
    private readonly translate: TranslocoService,
  ) {
    const enabledLangs = this._config.enabledLanguages || [];
    const routesFor = this._config.routesFor;
    if (routesFor === 'profitmarkua') {
      const withLangPathSegment = enabledLangs.map((lang) => ({
        path: lang,
        children: uaAppRoutes,
      }));

      this.router.resetConfig([
        ...uaAppRoutes,
        ...withLangPathSegment,
        {
          path: '**',
          component: PmCorePageNotFoundComponent,
        },
      ]);
    }

    /** Set transaltion based on first path part on navigation */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        const navEndEvent = event as NavigationStart;
        const [url] = navEndEvent.url.split(RegExp('(#|\\?)', 'g'));
        const lang = url?.split('/')[1];
        if (lang && enabledLangs.includes(lang)) {
          this.translate.setActiveLang(lang);
        } else {
          const defaultLanguage = this._config.localeCode || 'en';
          this.translate.setActiveLang(defaultLanguage);
        }
      });

    /** Scroll to top on same route navigation */
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        pairwise(),
      )
      .subscribe(([previousEvent, currentEvent]) => {
        if (
          previousEvent.urlAfterRedirects === currentEvent.urlAfterRedirects
        ) {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });

    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise(),
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else if (
          previousEvent.routerEvent instanceof NavigationEnd &&
          event.routerEvent instanceof NavigationEnd
        ) {
          // forward navigation
          if (
            previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !==
            event.routerEvent.urlAfterRedirects.split('?')[0]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
